import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
import Link from '../components/Link';
import TeachingCards from '../components/TeachingCards';
import RecentArticles from '../components/RecentArticles';
import PinnedArticles from '../components/PinnedArticles';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



  <div className="prose lg:prose-xl mx-auto">
      <h1>{`Hi, I'm Sam! 👋`}</h1>
      <p>{`It's really nice to meet you. I'm a director of Developer Relations (prev. `}<a parentName="p" {...{
          "href": "http://www.auth0.com"
        }}>{`Auth0`}</a>{`), an instructor for `}<a parentName="p" {...{
          "href": "http://www.egghead.io"
        }}>{`egghead`}</a>{`, and the author of `}<a parentName="p" {...{
          "href": "http://www.gettingstartedindevrel.com"
        }}>{`Getting Started in Developer Relations`}</a>{`. I live in the PNW with my family and our scruffy doggo and love spending time in the woods whenever I can.`}</p>
      <p>{`Growing in your career as a developer or developer advocate is not easy. I'm here to help you as best I can, not by giving you vague motivational speeches, but by sharing practical `}<strong parentName="p">{`microskills`}</strong>{` that compound and become transformative.`}</p>
      <p>{`If you're just getting to know me, start here:`}</p>
      <div className="flex sm:flex-row flex-col -my-5">
        <PinnedArticles mdxType="PinnedArticles" />
        <div {...{
          "className": "sm:ml-2 ml-0 w-40 flex items-center justify-center"
        }}>{`
  `}<img parentName="div" {...{
            "src": "/signpost.svg",
            "alt": "signpost icon"
          }}></img>
        </div>
      </div>
      <p>{`If you like these, feel free to:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#subscribe"
          }}>{`Sign up for the Developer Microskills newsletter`}</a>{` at the bottom of the page,`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/writing"
          }}>{`Check out the rest of my writing`}</a>{`, and`}</li>
        <li parentName="ul">{`Find me on `}<a parentName="li" {...{
            "href": "https://twitter.com/samjulien"
          }}>{`Twitter`}</a>{`.`}</li>
      </ul>
      <p>{`Looking forward to getting to know you!`}</p>
      <h2>{`Books & Courses`}</h2>
      <p>{`I also write books and make video courses! I love making complex topics accessible to new learners.`}</p>
      <TeachingCards mdxType="TeachingCards" />
      <br />
  </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      